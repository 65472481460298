<template><v-app>
  <div class="d-flex mb-4">
    <div class="d-flex align-self-start">
      <h1>Fluentify &lt;&gt; Mailchimp Batch {{ batchId  }} Details</h1>
    </div>
    <div><es-btn @click="() => fetchBatchDetails(batchId)" color="indigo"><fa icon="sync" /> Refresh</es-btn></div>
  </div>
    <v-card v-if="batchDetails" class="mb-6">
      <div class="pa-4">
        <div>
          Batch Status: <v-chip small label class="capitalize ma-2">{{ batchDetails.status }}</v-chip>

          <v-chip class="ma-2" small label color="success" text-color="white" variant="elevated">{{ `Success ${batchDetails.total_operations - batchDetails.errored_operations}` }}</v-chip>
          <v-chip class="ma-2" small label color="red" text-color="white" variant="elevated">{{ `Error ${batchDetails.errored_operations}` }}</v-chip>
          <v-chip class="ma-2" small label>Total Items {{ batchDetails.total_operations }}</v-chip>
          <div>Submitted At: {{  batchDetails.submitted_at | dateString }}</div>
          <div>Completed At: {{  batchDetails.completed_at | dateString }}</div>
        </div>
      </div>
    </v-card> 
    <v-card class="mb-6">
      <v-data-table 
        hide-default-footer
        fixed-header
        :loading="isBusy"
        :headers="reportHeader"
        :items="batchReport"
        height="70vh"
        loading-text="Loading items ..."
        no-data-text="No items found.">
        <div v-show="isBusy" slot="progress" class="dot-windmill"></div>
        <template v-slot:[`item.count`]="{ item }">
          <span class="capitalize">{{  item.value.length }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions d-flex">
            <es-btn
              title="View details"
              class="es-btn es-btn-copy ml-auto"
              color="primary"
              @click="() => showDetails(item)"
            >View Details</es-btn>
        </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" fullscreen>
      <v-card v-if="selectedItem && selectedItemData">
        <v-toolbar dark color="primary" fixed>
          <v-toolbar-title>Details for {{ selectedItemData.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <ul v-if="selectedItem" class="ul-items">
            <li v-for="item, $index in selectedItemData.items" :key="`item_${$index}_${item.operationId}`" class="li-items">
              <span v-if="item.operationId">{{  item.operationId  }}</span>
              <span v-if="item.email">{{  item.email  }}</span>
              <span v-if="item.detail">{{  item.detail  }}</span>
              <span v-if="item.errors">{{  item.errors  }}</span>
            </li>
          </ul>
        <v-footer fixed class="d-flex justify-center">
          <v-pagination v-model="currentPage" :length="totalPages"></v-pagination>
        </v-footer>
      </v-card>
    </v-dialog>
</v-app>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'FluentifyList',
  data: () => ({
    dialog: false,
    selectedItem: null,
    currentPage: 1,
    itemsPerPage: 500,
  }),
  computed: {
    ...mapGetters('fluentify', ['batchDetails', 'batchReport', 'reportHeader', 'isBusy']),
    batchId() {
      return this.$route.params.batchId
    },
    totalPages() {
      return this.selectedItem ? Math.ceil(this.selectedItem.value.length / this.itemsPerPage) : 0
    },
    selectedItemData() {
      const startIndex = (this.currentPage - 1 ) * this.itemsPerPage;
      const items = this.selectedItem.value.slice(startIndex, startIndex + this.itemsPerPage).map(selectedItemIndex => {
        const [batchIndex, responseIndex] = selectedItemIndex.split('_');
        const data = JSON.parse(this.batchDetails.batchResponse[batchIndex][responseIndex].response)
        return {
          operationId: this.batchDetails.batchResponse[batchIndex][responseIndex].operation_id?.replace('op_', '') ?? '',
          email: data.email_address,
          detail: data.detail,
          errors: data.errors ? data.errors.map(item => `${item.field} : ${item.message}`).join(',') : null
        }
      })
      return {
        title: this.selectedItem.title,
        items
      }
    }
  },
  methods: {
    ...mapActions('fluentify', ['fetchBatchDetails', 'clearBatchDetails']),
    showDetails(item) {
      this.selectedItem = item;
      this.currentPage = 1;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.selectedItem = false
    }
  },
  mounted() {
    this.fetchBatchDetails(this.batchId);
  },
  beforeDestroy() {
    this.clearBatchDetails();
  }
};
</script>
<style lang='scss' scoped>
.dot-windmill {
  position: absolute;
  top: 84px;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #220a5a;
  color: #220a5a;
  transform-origin: 5px 15px;
  -webkit-animation: dotWindmill 2s infinite linear;
  animation: dotWindmill 2s infinite linear;
}

.actions {
  text-align: right;
  margin-right: -20px;
}

.es-btn {
  font-size: 12px;
  padding: 7px;
  min-width: 32px;
  margin-left: 6px;
  margin-bottom: 2px;
}

::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
  .v-text-field .v-label {
    z-index: 100;
  }
  .v-data-table-header th {
    text-align: left;
    color: white !important;
    background-color: #220a5a !important;
    &:first-child {
      border-top-left-radius: 4px;
      padding-left: 34px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
    &.active,
    & .v-data-table-header__icon {
      color: #ffe300 !important;
    }
    &.sortable {
      white-space: nowrap;
    }
  }
  .v-data-table tbody tr {
    &.expired {
      opacity: 0.3;
      background: #fee;
      cursor: not-allowed;
    }
    & td {
      padding-top: 18px !important;
      padding-bottom: 18px !important;

      &:first-child {
        padding-left: 34px;
        background-color: inherit
      }
      &:last-child {
        padding-right: 34px;
      }
    }
  }
}
.capitalize {
  text-transform: capitalize;
}
.ul-items {
  margin-bottom: 60px;
  display: block;
  list-style: none;
  & .li-items {
    display: flex;
    gap: .5em;
    flex-direction: row;
  }
}
</style>
